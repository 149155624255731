import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, distinctUntilChanged, first, Observable, Subject, Subscription, switchMap, tap } from 'rxjs';

import { DialogService } from '@bend/dialog';
import { xCenterScrollTo } from '@bend/shared-widgets/src/lib/helpers';
import { CatalogReloadService, OptimisticService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { ItemUnavailabilityDisplay } from '@bend/store';
import { CategoryStyleType, MenuItemStyleType, MenuStyleType, MenuType } from '@bend/store-shared';
import { ViewportService } from '@bend/viewport';
import { PricesService } from '@bend/widgets-old/product-catalog/services/prices.service';
import { WaiterModeService } from '@bend/widgets-old/product-catalog/services/waiter-mode.service';

import { LinkService } from '../../link.service';
import { ErrorHandlerService } from '../../shared/error-handler/error-handler.service';
import { PopCurrency } from '../../widget';
import { isTemporarilyUnavailable } from '../helpers';
import { WidgetProductCatalogMenuDetailsDialogComponent } from '../menu-details-dialog/menu-details-dialog.component';
import { PageAnalytics, PopCartVisitor, PopMenu, PopMenuCategory, PopProduct } from '../product-catalog';
import { WidgetProductCatalogService } from '../product-catalog.service';
import { WidgetProductCatalogHelperService } from '../product-catalog-helper.service';
import { WidgetProductCatalogSharedCartDialogComponent } from '../shared-cart-dialog/shared-cart-dialog.component';
import { MenuService } from './services/menu.service';

@Component({
  selector: 'pop-product-catalog-menu',
  templateUrl: './product-catalog-menu.component.html',
  styleUrls: ['./product-catalog-menu.component.scss'],
})
export class WidgetProductCatalogMenuComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() catalogId: string;
  @Input() currency: PopCurrency;
  @Input() isReadOnly: boolean;
  @Input() isConsultingMode: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  @Input() allowOrderForOthers: boolean;
  @Input() mainColor: string;
  @Input() secondColor: string;
  @Input() textColor: string;
  @Input() allowCommonPot: boolean;
  @Input() visibleMenus: number[];
  @Input() showKitchenComment: boolean;
  @Input() isCommentToKitchenRequired: boolean;
  @Input() forceShowProductDescription: boolean;
  @Input() returnUrl: string;
  @Input() pageAnalytics: PageAnalytics;
  @Input() styleType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() styleConfig: any;
  @Input() menuStyleType: string;
  @Input() styleNumber: number;
  @ViewChildren('subcategory') subcategories: QueryList<ElementRef>;
  @ViewChild('menuCategoriesEl') menuCategoriesEl: ElementRef;
  @ViewChildren('menusRef') menusRef: QueryList<ElementRef>;
  menuItemAdded: Subject<any> = new Subject();
  menuCategories: PopMenuCategory[] = [];
  menus: PopMenu[] = [];
  errorCategories: number[] = [];
  menu: PopMenu;
  commentToKitchen: string;
  total: number;
  isAnyErrorCategory: boolean;
  isScrollToErrorCategoryActive: boolean;
  resetCommentAndCloseEmitter = new EventEmitter<any>();
  isLoading: boolean;
  isCommentToKitchenInvalid: boolean;
  showErrors: boolean;
  selectedProducts: any[] = [];
  showStepper: boolean;
  productCloneProperty: PopProduct;
  menuItemStyleType$: Observable<MenuItemStyleType>;
  itemUnavailabilityDisplayEnum = ItemUnavailabilityDisplay;
  menuTypes = MenuType;
  menuStyleTypes = MenuStyleType;
  menuItemStyleTypes = MenuItemStyleType;
  categoryStyleTypes = CategoryStyleType;
  secondColor$: Observable<string>;

  _subscription: Subscription;

  constructor(
    private _productCatalogService: WidgetProductCatalogService,
    public helperService: WidgetProductCatalogHelperService,
    private _dialogMat: MatDialog,
    private _linkService: LinkService,
    private _errorHandlerService: ErrorHandlerService,
    private _optimistic: OptimisticService,
    protected menuService: MenuService,
    protected _cdr: ChangeDetectorRef,
    protected _reloadCatalog: CatalogReloadService,
    protected _viewport: ViewportService,
    protected _dialog: DialogService,
    protected pricesService: PricesService,
    public waiterModeService: WaiterModeService,
  ) {
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    this._subscription.add(
      this.menuService.showStepByStepMenu.subscribe(val => {
        this.showStepper = val;
        this._cdr.detectChanges();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.catalogId || changes.visibleMenus) {
      this.loadMenus();
      this._reloadOnNoStockItem();
    }
  }

  ngAfterViewInit(): void {
    this.scrollToFirstErrorCategory();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  loadMenus(): void {
    this.pricesService.flow
      .pipe(
        distinctUntilChanged(),
        switchMap(flow => {
          return this._productCatalogService.getMenus(this.catalogId).pipe(
            tap(menus => {
              const availableToShowMenus = menus.filter(
                menu => menu.available || this.itemUnavailabilityDisplay === ItemUnavailabilityDisplay.OutOfStock,
              );
              const visibleMenus = availableToShowMenus.filter(menu => this.visibleMenus.includes(menu.id));
              const sortedMenus = this.visibleMenus.length ? visibleMenus : availableToShowMenus;
              sortedMenus.sort(this.helperService.sortByOrder);
              sortedMenus.forEach((menu, index) => (menu.order = index + 1));
              // update menus with flow prices
              this.menus = sortedMenus.map(menu => this.pricesService.getUpdatedProductByFlow(menu, flow));
              if (this.menus.length > 0) {
                this.loadMenuCategories(this.menus[0]);
              }
              this._cdr.detectChanges();
            }),
          );
        }),
      )
      .subscribe();
  }

  loadMenuCategories(menu: PopMenu): void {
    this.selectedProducts = [];
    this.menu = menu;

    this.pricesService.flow
      .pipe(
        distinctUntilChanged(),
        switchMap(flow =>
          this._productCatalogService.getMenuCategories(menu.id.toString()).pipe(
            tap(itemCategories => {
              itemCategories.sort(this.helperService.sortByOrder);

              itemCategories.forEach(category => {
                category.menuCategoryItems.sort(this.helperService.sortByOrder);
                category.menuCategoryItems = category.menuCategoryItems.map(menuCategoryItem => {
                  this.helperService.sortProductsAndOptionsByOrder([menuCategoryItem.item]);
                  return {
                    // return updated menuCategoryItem
                    ...this.pricesService.getUpdatedProductByFlow(menuCategoryItem, flow),
                    // return updated item
                    item: {
                      ...this.pricesService.getUpdatedProductByFlow(
                        // get prices for item form menuCategoryItem
                        { ...menuCategoryItem.item, prices: menuCategoryItem.prices },
                        flow,
                      ),
                    },
                  };
                });
                // update options price for menuCategoryItems
                category.menuCategoryItems.forEach(menuCategoryItem => {
                  menuCategoryItem.item.itemOptionGroups.forEach(itemOptionGroup => {
                    itemOptionGroup.optionGroup.optionGroupSubitems.forEach(optionGroupSubitem => {
                      optionGroupSubitem.item = this.pricesService.getUpdatedProductByFlow(
                        optionGroupSubitem.item,
                        flow,
                      );
                    });
                  });
                });
              });

              this.menuCategories = itemCategories;
              this.helperService.calculateMenuItemsTotal(this.menuCategories);
              this.total = this.helperService.calculateMenuTotal(this.menu, this.menuCategories);
              if (this.menusRef?.length) xCenterScrollTo(this.menusRef.get(this.menu.order - 1).nativeElement);
              this._cdr.detectChanges();
            }),
          ),
        ),
      )
      .subscribe();
    this._resetErrors();
  }

  loadProductDetails(
    product: PopProduct,
    menuPrice: number,
    categoryId: number,
    { showDetails, forceDetailsForMenu }: { showDetails: boolean; forceDetailsForMenu?: boolean },
    category: PopMenuCategory,
  ): void {
    const selectedQuantity = category.menuCategoryItems.reduce((acc, curr) => acc + (curr.item.quantity || 0), 0);

    const productClone: PopProduct = JSON.parse(JSON.stringify(product));
    productClone.categoryId = categoryId;

    if (!showDetails && (product.isSelected || product.quantity)) {
      this._selectMenuItem(productClone, true);
      return;
    }

    if (
      product.children?.length ||
      (product.itemOptionGroups && product.itemOptionGroups.length > 0) ||
      (category.allowQuantity && showDetails && !forceDetailsForMenu) ||
      (forceDetailsForMenu && product.description && this.forceShowProductDescription)
    ) {
      if (selectedQuantity !== 0 && selectedQuantity === category.max && !showDetails) {
        this._selectMenuItem(productClone, true);
        return;
      }

      this.showProductDetailsDialog(product, menuPrice, categoryId, category);
    } else {
      if (this.isConsultingMode) return;

      if (this.isReadOnly) {
        this._dialog.info({ message: 'CART.ORDER_NOT_AVAILABLE' });
        return;
      }
      if (this.isReadOnlyMenu || !product.available || isTemporarilyUnavailable(product)) return;

      // don't add more items than necessary for category
      if (showDetails && selectedQuantity > 1 && selectedQuantity === category.max) return;

      this._selectMenuItem(productClone, !showDetails);
    }
  }

  showProductDetailsDialog(
    product: PopProduct,
    menuPrice: number,
    categoryId: number,
    category: PopMenuCategory,
  ): void {
    this._sortOptionGroups(product);
    const productClone: PopProduct = JSON.parse(JSON.stringify(product));
    productClone.priceFinalValue = menuPrice;
    productClone.categoryId = categoryId;
    this.productCloneProperty = productClone;
    const dialogRef = this._dialogMat.open(WidgetProductCatalogMenuDetailsDialogComponent, {
      maxWidth: 'none',
      width: '100vw',
      height: '100%',
      data: {
        category,
        product: productClone,
        isReadOnlyMenu: this.isReadOnlyMenu,
        attributes: {
          isReadOnly: this.isReadOnly,
          isConsultingMode: this.isConsultingMode,
          mainColor: this.mainColor,
          textColor: this.textColor,
          currency: this.currency,
          styleConfig: this.styleConfig,
          itemUnavailabilityDisplay: this.itemUnavailabilityDisplay,
        },
        pageAnalytics: this.pageAnalytics,
        isMenu: true,
      },
      panelClass: 'product-details-dialog-overlay',
    });

    dialogRef.afterClosed().subscribe((result: PopProduct) => {
      if (result) {
        this._selectMenuItem(result, false);
      }
      this._cdr.detectChanges();
    });
  }

  addToCart(): void {
    this.showErrors = false;
    if (this.isLoading) {
      return;
    }

    this.errorCategories = this.helperService.getAllRequiredMenuItemsNotSelected(this.menuCategories);
    if (this.errorCategories.length) {
      this.isAnyErrorCategory = true;
      this.isScrollToErrorCategoryActive = true;
      return;
    }

    if (this.isCommentToKitchenRequired && !this.commentToKitchen) {
      this.isCommentToKitchenInvalid = true;
      this.showErrors = true;
      return;
    }

    this.waiterModeService.isWaiterMode$
      .pipe(
        first(),
        tap(isWaiterUser => {
          if (this.allowOrderForOthers && !isWaiterUser) {
            this._showSharedCartDialog(this.menu);
          } else {
            this._saveProductToCart(null);
          }
        }),
      )
      .subscribe();

    this.isScrollToErrorCategoryActive = false;
    this.resetCommentAndCloseEmitter.emit();
  }

  onCommentChanged(comment: string): void {
    this.commentToKitchen = comment;
  }

  scrollToFirstErrorCategory(): void {
    this.subcategories.changes.subscribe((elements: ElementRef[]) => {
      if (this.isScrollToErrorCategoryActive) {
        const firstSubcategory = elements.find(
          element => element.nativeElement.className.indexOf('ng-category-with-error') >= 0,
        );
        if (firstSubcategory) {
          // TODO: replace hardcoded 44 number with other solution;
          this._scrollToPosition(firstSubcategory.nativeElement.getBoundingClientRect().top - 44);
        }
        this.isScrollToErrorCategoryActive = false;
        this._cdr.detectChanges();
      }
    });
  }

  getStyle(id: number): string {
    return id === this.menu.id ? this.secondColor : 'grey';
  }

  foldCategory(category: PopMenuCategory): void {
    category.folded = !category.folded;
  }

  isItemShown(product: any, category: PopMenuCategory): boolean {
    const isProductSelected = this.selectedProducts.find(selected => {
      return selected.productId === product.item.id && selected.categoryId === category.id;
    });

    return (category.folded && isProductSelected) || !category.folded;
  }

  navigate(menu: PopMenu): void {
    if (!menu.available || isTemporarilyUnavailable(this.menu)) return;

    this.menu = menu;
    this.menuService.toggleStepperStatus();
  }

  // TD Strict Template (Serghei)
  // Add empty function for resolve problem
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeStepperState(event: boolean): void {}

  private _reloadOnNoStockItem(): void {
    /**
     * @description reload catalog if exists no stock items
     * if payment failed and receive POS_NO_STOCK error, get product items without no stock items
     */
    this._subscription.add(
      this._reloadCatalog.onReload
        .pipe(
          tap(() => {
            /**
             * @description reset catalog store
             */
            this._productCatalogService.resetCatalogStore();
            this.loadMenus();
          }),
        )
        .subscribe(),
    );
  }

  private _showSharedCartDialog(menu: PopMenu): void {
    const dialogRef = this._dialogMat.open(WidgetProductCatalogSharedCartDialogComponent, {
      maxWidth: 'none',
      width: '100vw',
      height: '100%',
      data: {
        product: menu,
        attributes: {
          mainColor: this.mainColor,
          allowCommonPot: this.allowCommonPot,
        },
      },
      panelClass: 'shared-cart-dialog-overlay',
    });

    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this._saveProductToCart(user);
        this._cdr.detectChanges();
      }
    });
  }

  private _saveProductToCart(user: PopCartVisitor): void {
    const orderItem = this.helperService.generateMenuOrderItem(
      this.menuCategories,
      this.commentToKitchen,
      this.menu.id,
      this.menu.name,
      this.menu.price,
      user,
    );
    this.isLoading = true;

    this._productCatalogService
      .addItemToCart(orderItem)
      .pipe(
        /**
         * @description start optimistic update order
         */
        tap(() => this._optimistic.checkNewItem()),
        /**
         * @description create new order on pick up or delivery
         */
        catchError(error =>
          this.helperService.handleOrderClose(error).pipe(
            tap(isCreatedOrder => {
              if (isCreatedOrder) this._saveProductToCart(user);
              else {
                this._errorHandlerService.handleHttpError(error, orderItem.orderItem);
                this.isLoading = false;
              }
              this._cdr.detectChanges();
            }),
          ),
        ),
      )
      .subscribe(() => {
        this.helperService.resetSelectedMenuItems(this.menuCategories);
        this.total = this.helperService.calculateMenuTotal(this.menu, this.menuCategories);
        this._handleMenuAddedToCart();
        this.selectedProducts = [];

        // for menu type - single page
        // need reset category items quantity, because we don't make request again
        if (!this.showStepper) {
          this.menuCategories.forEach(category => {
            this.helperService.unselectProductsFromMenuCategory(category);
          });
        }

        this._cdr.detectChanges();
      });
  }

  private _navigateToReturnUrl(): void {
    if (this.returnUrl) {
      this._linkService.navigate(this.returnUrl, false, this.isReadOnly);
    }
  }

  private _scrollToPosition(position: number): void {
    if (window) {
      window.scroll(0, window.scrollY + position);
    }
  }

  getAllErrorMessages(): void {
    if (this.isAnyErrorCategory) {
      this.errorCategories = this.helperService.getAllRequiredMenuItemsNotSelected(this.menuCategories);
    }
  }

  private _resetErrors(): void {
    this.errorCategories = [];
    this.isAnyErrorCategory = false;
  }

  private _selectMenuItem(product: PopProduct, unselect: boolean): void {
    this.helperService.selectMenuItem(this.menuCategories, product, !unselect);
    this._addProductToSelected(product, unselect);
    this.total = this.helperService.calculateMenuTotal(this.menu, this.menuCategories);
    this.getAllErrorMessages();
  }

  private _addProductToSelected(product: PopProduct, unselect: boolean): void {
    const index = this.selectedProducts.findIndex(selected => selected.categoryId === product.categoryId);
    const category = this.menuCategories.find(cat => cat.id === product.categoryId);

    if (index >= 0 && !unselect && category.max === 1) {
      this.selectedProducts.splice(index, 1);
    }

    if (unselect) {
      this.selectedProducts = this.selectedProducts.filter(
        ({ productId, categoryId }) => productId !== product.id && category.id !== categoryId,
      );
      category.menuCategoryItems.find(({ item }) => product.id === item.id).item.quantity = 0;
      return;
    }

    const selectedQuantity = category.menuCategoryItems.reduce((acc, curr) => acc + (curr.item.quantity || 0), 0);
    if (selectedQuantity === category.max) {
      this.menuItemAdded.next(!unselect);
    }

    this.selectedProducts.push({
      name: product.name,
      description: product.description,
      categoryId: product.categoryId,
      productId: product.id,
    });
  }

  private _handleMenuAddedToCart(): void {
    this.isLoading = false;
    this.menuService.closeStepper();
    this._navigateToReturnUrl();
  }

  private _sortOptionGroups(product: any): void {
    product.itemOptionGroups.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }

  get isReadOnlyMenu(): boolean {
    return !this.menu.available || isTemporarilyUnavailable(this.menu);
  }

  get canAddToCart(): boolean {
    const selected = [];

    this.menuCategories.forEach(cat => {
      const asa = cat.menuCategoryItems.reduce(
        (acc, item) => (item.item.isSelected ? (item.item.quantity || 1) + acc : acc),
        0,
      );

      if (asa >= cat.min) selected.push(cat.id);
    });

    return selected.length === this.menuCategories.length;
  }
}
