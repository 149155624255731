import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { SettingsService } from '@bend/store';
import { OrderItemStatus } from '@bend/store-shared';

import { OrderDialogLabels } from '../../../../config';
import { PriorityStatus } from '../../../../types';
import { BehaviorTapInput } from '../kiosk-mode-behavior/kiosk-mode-behavior.service';
import { OtherDialogService } from '../other-dialog/other-dialog.service';

@Injectable()
export class OrderDialogPaymentService {
  constructor(
    private _settings: SettingsService,
    private _dialog: DialogService,
    private _other: OtherDialogService,
  ) {}

  waitingBehavior(): MonoTypeOperatorFunction<BehaviorTapInput> {
    const allowStatuses = new Set([OrderItemStatus.WaitingForPayment]);

    return tap(([status, , orderNumber, , , isKioskMode]) => {
      if (!allowStatuses.has(status) || isKioskMode) return;

      // show in success modal
      this._dialog.success({
        message: this._settings.cashPaymentOnly.pipe(
          map(cashPaymentOnly => {
            if (!cashPaymentOnly) return OrderDialogLabels.PaymentWaitingFromAll;

            // waiting from the pop or waiter depending on the 'allowCashPayment' setting
            // currently don't have payment waiting from pop
            return OrderDialogLabels.PaymentWaitingFromWaiter;
          }),
        ),
        interpolateData: { orderNumber },
      });
    });
  }

  progressBehavior(): MonoTypeOperatorFunction<PriorityStatus> {
    const allowStatuses = new Set([OrderItemStatus.PaymentInProgress]);

    return tap(({ status }) => {
      // show only in status allowed
      if (!allowStatuses.has(status)) return;

      this._other.paymentInProgress();
    });
  }

  errorBehavior(): MonoTypeOperatorFunction<BehaviorTapInput> {
    const allowStatuses = new Set([OrderItemStatus.PaymentNotSent]);

    return tap(([status, , orderNumber]) => {
      // show only in status allowed
      if (!allowStatuses.has(status)) return;

      // show in error modal
      this._dialog.error({
        message: OrderDialogLabels.PaymentError,
        interpolateData: { orderNumber },
      });
    });
  }

  readyBehavior(): MonoTypeOperatorFunction<BehaviorTapInput> {
    const allowStatuses = new Set([OrderItemStatus.Paid]);

    return tap(([status, , orderNumber]) => {
      // show only in status allowed
      if (!allowStatuses.has(status)) return;

      // show in success modal
      this._dialog.success({
        message: OrderDialogLabels.PaymentSuccess,
        interpolateData: { orderNumber },
      });
    });
  }
}
