import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiVisitorService, ConfirmCode } from '../shared';
import { LoginParams, LoginParamsGps, LoginParamsPos, LoginParamsWaiter, LoginResponse } from './auth.type';

@Injectable()
export class AuthHttpService {
  constructor(
    private _http: HttpClient,
    private _api: ApiVisitorService,
  ) {}

  login(params: LoginParams): Observable<LoginResponse> {
    return this._api.login('v1').pipe(switchMap(api => this._http.post<LoginResponse>(api, params)));
  }

  loginWaiter({ orderId, ...params }: LoginParamsWaiter): Observable<LoginResponse> {
    return this._api.loginWaiter('v2', orderId).pipe(switchMap(api => this._http.post<LoginResponse>(api, params)));
  }

  loginPos(params: LoginParamsPos): Observable<LoginResponse> {
    return this._api.loginPos('v1').pipe(switchMap(api => this._http.post<LoginResponse>(api, params)));
  }

  loginWifi(): Observable<LoginResponse> {
    return this._api.loginWifi('v1').pipe(switchMap(api => this._http.post<LoginResponse>(api, {})));
  }

  loginGps(params: LoginParamsGps): Observable<LoginResponse> {
    return this._api.loginGps('v1').pipe(switchMap(api => this._http.post<LoginResponse>(api, params)));
  }

  signIn(phone: string): Observable<any> {
    return this._api.signIn('v1').pipe(switchMap(url => this._http.post(url, { phone })));
  }

  confirmPhone(profileData: ConfirmCode): Observable<LoginResponse> {
    return this._api.confirmPhone('v1').pipe(switchMap(api => this._http.post<LoginResponse>(api, profileData)));
  }

  resendCode(phone: string): Observable<any> {
    return this._api.resendCode('v1').pipe(switchMap(api => this._http.post(api, { phone })));
  }
}
