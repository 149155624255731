import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { iif, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { xCenterScrollTo } from '@bend/shared-widgets/src/lib/helpers';
import { CatalogReloadService, OptimisticService } from '@bend/shared-widgets/src/lib/shared/cart-services';
import { CurrencyPipe } from '@bend/shared-widgets/src/lib/shared/shared-components/pipes';
import { ErrorCodes } from '@bend/shared-widgets/src/lib/types';
import {
  ItemUnavailabilityDisplay,
  PagesService,
  ParamsService,
  RecommendationService,
  SettingsService,
} from '@bend/store';
import { CategoryStyleType } from '@bend/store-shared';
import { ViewportService } from '@bend/viewport';
import { PricesService } from '@bend/widgets-old/product-catalog/services/prices.service';
import { WaiterModeService } from '@bend/widgets-old/product-catalog/services/waiter-mode.service';

import { LinkService } from '../../link.service';
import { ErrorHandlerService } from '../../shared/error-handler/error-handler.service';
import { TranslateHelperService } from '../../translate-helper.service';
import { PopCurrency } from '../../widget';
import { isTemporarilyUnavailable } from '../helpers';
import {
  PageAnalytics,
  PopCartVisitor,
  PopProduct,
  PopProductCategory,
  PopProductSubcategory,
  ProductCatalogStyleConfig,
} from '../product-catalog';
import { WidgetProductCatalogService } from '../product-catalog.service';
import { WidgetProductCatalogHelperService } from '../product-catalog-helper.service';
import { WidgetProductCatalogDetailsDialogComponent } from '../product-details-dialog/product-details-dialog.component';
import { WidgetProductCatalogSharedCartDialogComponent } from '../shared-cart-dialog/shared-cart-dialog.component';

const LABEL_PREFIX = 'PRODUCT_CATALOG';

@Component({
  selector: 'pop-product-catalog-a-la-carte',
  templateUrl: './product-catalog-a-la-carte.component.html',
  styleUrls: ['./product-catalog-a-la-carte.component.scss'],
})
export class WidgetProductCatalogALaCarteComponent implements OnChanges, OnInit, OnDestroy {
  @Input() catalogId: string;
  @Input() currency: PopCurrency;
  @Input() isReadOnly: boolean;
  @Input() isConsultingMode: boolean;
  @Input() itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  @Input() allowOrderForOthers: boolean;
  @Input() allowCommonPot: boolean;
  @Input() mainColor: string;
  @Input() secondColor: string;
  @Input() textColor: string;
  @Input() visibleCategories: number[];
  @Input() showKitchenComment: boolean;
  @Input() isCommentToKitchenRequired: boolean;
  @Input() forceShowProductDescription: boolean;
  @Input() returnUrl: string;
  @Input() pageAnalytics: PageAnalytics;
  @Input() styleType: string;
  @Input() categoryStyleType: number;
  @Input() styleNumber: number;
  @Input() styleConfig: ProductCatalogStyleConfig;
  @Input() workingHours: any;
  @Input() numberOfCatalogs: number;
  @Input() catalogsNavHeight: number;
  @Input() topBar: {
    display: boolean;
  };

  @ViewChildren('categoriesRef') categoriesRef: QueryList<ElementRef>;
  @ViewChild('scrollIndicatorEnd') scrollIndicatorEndEl: ElementRef;
  @ViewChild('contentWrapperEl') contentWrapperEl: ElementRef;

  productCategories: PopProductCategory[] = [];
  productSubcategories: PopProductSubcategory[] = [];

  currentCategory: PopProductCategory;
  categoryStyleTypes = CategoryStyleType;
  scrollToDefaultCategorySlide: boolean; // this flag is used to avoid double scrolling off categoriesSlider element
  isLoading: boolean;
  currentCategoryDescription: string;
  currentCategoryImageBackground: string;
  loadingCategoryId: number;
  loadingProductId: number;
  isTopBarVisibleProp: string;

  itemUnavailabilityDisplayEnum = ItemUnavailabilityDisplay;

  isMobile$: Observable<boolean>;
  showCatalogsOnTop$: Observable<boolean>;
  secondaryForeground$: Observable<string>;

  alacarteItems$: Observable<PopProduct[]>;

  private _subscription: Subscription = new Subscription();
  private _showCatalogsOnTop: Subject<boolean>;

  constructor(
    private _productCatalogService: WidgetProductCatalogService,
    private _helperService: WidgetProductCatalogHelperService,
    private _matDialog: MatDialog,
    private _linkService: LinkService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _errorHandlerService: ErrorHandlerService,
    private _translateHelper: TranslateHelperService,
    private _params: ParamsService,
    private _viewport: ViewportService,
    private _dialog: DialogService,
    private _settings: SettingsService,
    private _cdr: ChangeDetectorRef,
    private _reloadCatalog: CatalogReloadService,
    private _optimistic: OptimisticService,
    private _catalogReload: CatalogReloadService,
    private _page: PagesService,
    private _recommendations: RecommendationService,
    private _pricesService: PricesService,
    private waiterModeService: WaiterModeService,
  ) {
    this.isMobile$ = this._viewport.isMobile;
    this._showCatalogsOnTop = new Subject();
    this.showCatalogsOnTop$ = this._showCatalogsOnTop.asObservable().pipe(distinctUntilChanged());
    this.secondaryForeground$ = this._page.secondaryForeground;
  }

  ngOnInit(): void {
    this._getSessionSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.catalogId || changes.visibleCategories) {
      if (changes.visibleCategories) {
        this.visibleCategories = this.visibleCategories || [];
      }

      this._subscription.unsubscribe();
      this._subscription = new Subscription();
      this.productSubcategories = [];
      this.loadCategories();
      this._reloadOnNoStockItem();
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  loadCategories(): void {
    this._productCatalogService
      .getProductCategories(this.catalogId)
      .pipe(first())
      .subscribe(itemCategories => {
        const visibleCategories = itemCategories.filter(category => this.visibleCategories.includes(category.id));
        this.productCategories = this.visibleCategories.length ? visibleCategories : itemCategories;
        this.productCategories.sort(this._helperService.sortByOrder);
        const defaultCategory = this.productCategories.find(category => category.isDefault);

        if (this.productCategories.length === 0) {
          this.productSubcategories = [];
          this.currentCategoryDescription = null;
          this.currentCategory = null;
          this._cdr.detectChanges();
          return;
        }

        this.currentCategory = defaultCategory || this.productCategories[0];
        this.scrollToDefaultCategorySlide = true;
        this.subcategoriesAutoselect();
        this._cdr.detectChanges();
      });
  }

  subcategoriesAutoselect(): void {
    this._subscription.add(
      this._params.queryParamsChanges().subscribe(({ categoryId }) => {
        if (categoryId && categoryId < this.productCategories.length) {
          this.loadSubcategories(this.productCategories[Number(categoryId)]);
        } else {
          this.loadSubcategories(this.currentCategory);
        }
        this._cdr.detectChanges();
      }),
    );
  }

  loadSubcategories(category: PopProductCategory): void {
    this.currentCategory = category;
    this.currentCategoryImageBackground = this.currentCategory.imageUrl
      ? `url(${this.currentCategory.imageUrl})`
      : 'inherit';
    this.isLoading = true;
    this.loadingCategoryId = category.id;

    this.alacarteItems$ = this._pricesService.flow.pipe(
      distinctUntilChanged(),
      switchMap(flow =>
        this._productCatalogService.getProductSubcategories(category.id.toString()).pipe(
          map(response => {
            response.itemCategories.sort(this._helperService.sortByOrder);
            response.itemCategories.forEach(item => {
              this._helperService.sortProductsAndOptionsByOrder(item.items);
              // update prices for itemCategories.items
              item.items = item.items.map(item => {
                return {
                  ...this._pricesService.getUpdatedProductByFlow(item, flow),
                  itemOptionGroups: this._pricesService.updatedItemOptionGroups(item.itemOptionGroups, flow),
                };
              });
              // update subproducts from itemCategories.items.children
              item.items.forEach(item => {
                item.children = item.children.map(child => this._pricesService.getUpdatedProductByFlow(child, flow));
              });
            });
            this._helperService.sortProductsAndOptionsByOrder(response.items);

            this.productSubcategories = response.itemCategories;
            this.currentCategoryDescription = this.currentCategory.description;
            this.loadingCategoryId = null;
            this.isLoading = false;
            this._cdr.detectChanges();

            // Modify price for options
            response.items.forEach(item => {
              item.itemOptionGroups.forEach(group => {
                group.optionGroup.optionGroupSubitems.forEach(subItem => {
                  subItem.item = this._pricesService.getUpdatedProductByFlow(subItem.item, flow);
                });
              });
            });

            // Modify price for children
            response.items.forEach(item => {
              item.children = item.children.map(child => this._pricesService.getUpdatedProductByFlow(child, flow));
            });

            // Modify price for products
            return response.items.map(item => this._pricesService.getUpdatedProductByFlow(item, flow));
          }),
          catchError((error: HttpErrorResponse) => {
            this.loadingCategoryId = null;
            this._handleHttpError(error);

            this._cdr.detectChanges();
            return of(null);
          }),
        ),
      ),
    );
  }

  selectCatalog(index: number): void {
    this._scrollToSelectedCategory(index);
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        categoryId: index,
      },
      queryParamsHandling: 'merge',
    });
  }

  getStyleBg(id: number): string {
    const bgColor = id === this.currentCategory.id ? this.secondColor : 'grey';

    return bgColor;
  }

  // TD Strict Template (Serghei) subcategoryImageUrl -> not required
  loadProductDetails(
    product: PopProduct,
    { type, showDetails }: { type: string; showDetails: boolean },
    subcategoryImageUrl?: string,
  ): void {
    if (
      (product.itemOptionGroups && product.itemOptionGroups.length > 0) ||
      product.children.length ||
      product.hasRecommendations ||
      /**
       * @description show product description only is forced by settings and has image or description
       */
      (showDetails && this.forceShowProductDescription && (product.imageUrl || product.description))
    ) {
      this.showProductDetailsDialog(product, subcategoryImageUrl);
    } else {
      this.addToCart(product, type);
    }
  }

  showProductDetailsDialog(product: PopProduct, subcategoryImageUrl: string): void {
    const productClone = JSON.parse(JSON.stringify(product));
    if (!productClone.imageUrl) {
      productClone.imageUrl = subcategoryImageUrl;
    }

    const dialogRef = this._matDialog.open(WidgetProductCatalogDetailsDialogComponent, {
      maxWidth: 'none',
      width: '100vw',
      height: '100%',
      data: {
        product: productClone,
        attributes: {
          isReadOnly: this.isReadOnly,
          isConsultingMode: this.isConsultingMode,
          allowOrderForOthers: this.allowOrderForOthers,
          mainColor: this.mainColor,
          secondColor: this.secondColor,
          textColor: this.textColor,
          allowCommonPot: this.allowCommonPot,
          showKitchenComment: this.showKitchenComment,
          isCommentToKitchenRequired: this.isCommentToKitchenRequired,
          currency: this.currency,
          styleConfig: this.styleConfig,
          itemUnavailabilityDisplay: this.itemUnavailabilityDisplay,
        },
        styleType: this.styleType,
        styleNumber: this.styleNumber,
        isMenu: false,
        pageAnalytics: this.pageAnalytics,
      },
      panelClass: 'product-details-dialog-overlay',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        tap(() => this._recommendations.resetQuantity()),
      )
      .subscribe();
  }

  addToCart(product: PopProduct, type: string): void {
    if (this.isConsultingMode) return;

    if (this.isReadOnly || product.isReadOnly) {
      this._dialog.info({ message: 'CART.ORDER_NOT_AVAILABLE' });
      return;
    }

    if (!product.available || isTemporarilyUnavailable(product)) return;

    this.waiterModeService.isWaiterMode$
      .pipe(
        first(),
        tap(isWaiterUser => {
          if (this.allowOrderForOthers && !isWaiterUser) {
            this.showSharedCartDialog(product, type);
          } else {
            this._saveProductToCart(product, type, null);
          }
        }),
      )
      .subscribe();
  }

  showSharedCartDialog(product: PopProduct, type: string): void {
    const dialogRef = this._matDialog.open(WidgetProductCatalogSharedCartDialogComponent, {
      maxWidth: 'none',
      width: '100vw',
      height: '100%',
      data: {
        product,
        attributes: {
          mainColor: this.mainColor,
          allowCommonPot: this.allowCommonPot,
        },
      },
      panelClass: 'shared-cart-dialog-overlay',
    });

    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this._saveProductToCart(product, type, user);
        this._cdr.detectChanges();
      }
    });
  }

  isCategoriesOverflown = ({ scrollWidth, clientWidth }) => {
    return scrollWidth > clientWidth;
  };

  private _scrollToSelectedCategory(index: number): void {
    const categoryEl = this.categoriesRef.find((_, i) => i === index);

    if (categoryEl) xCenterScrollTo(categoryEl.nativeElement);
  }

  private _reloadOnNoStockItem(): void {
    /**
     * @description reload catalog if exists no stock items
     * if payment failed and receive POS_NO_STOCK error, get product items without no stock items
     */
    this._subscription.add(
      this._reloadCatalog.onReload
        .pipe(
          tap(() => {
            /**
             * @description reset catalog store
             */
            this._productCatalogService.resetCatalogStore();
            this.loadCategories();
          }),
        )
        .subscribe(),
    );
  }

  private _saveProductToCart(product: PopProduct, type: string, user: PopCartVisitor): void {
    const quantity = product.quantityMultiplier;
    const comment = '';
    const orderItem = this._helperService.generateALaCarteItem(product, quantity, comment, user);
    this.isLoading = true;
    this.loadingProductId = product.id;
    this._productCatalogService
      .addItemToCart(orderItem)
      .pipe(
        /**
         * @description start optimistic update order
         */
        tap(() => this._optimistic.checkNewItem()),
        /**
         * @description create new order on pick up or delivery
         */
        catchError(error =>
          this._helperService.handleOrderClose(error).pipe(
            tap({
              next: isCreatedOrder => {
                if (isCreatedOrder) this._saveProductToCart(product, type, user);
                else this._handleHttpError(error);

                this.loadingProductId = null;
                this._cdr.detectChanges();
              },
              error: () => this._handleHttpError(error),
            }),
          ),
        ),
      )
      .subscribe(() => {
        this._handleProductAddedToCart();
        this._cdr.detectChanges();

        /**
         * create currency pipe to convert amount
         */
        const pipe = new CurrencyPipe(this._settings);
        /**
         * convert amount number in human readable
         */
        const amount = pipe.transform(product.priceFinalValue);

        if (type === 'fromQuery')
          this._dialog.success({
            message: 'PRODUCT_CATALOG.DIALOG.ADDED',
            interpolateData: {
              amount,
              name: product.name,
            },
          });
      });
  }

  private _handleProductAddedToCart(): void {
    this.isLoading = false;
    this.loadingProductId = null;
    this._navigateToReturnUrl();
  }

  private _navigateToReturnUrl(): void {
    if (this.returnUrl) {
      this._linkService.navigate(this.returnUrl, false, this.isReadOnly);
    }
  }

  private _translateLabel(label: string, prefix?: string): Observable<string> {
    return this._translateHelper.translateLabel(label, prefix ? prefix : LABEL_PREFIX);
  }

  private _handleHttpError(error: HttpErrorResponse): void {
    const isNoActiveOrderError = ['DISABLE_ADDITIONS_AFTER_ORDER_SESSION_ORDERED', 'NO_ACTIVE_ORDER_FOR_USER'].includes(
      error?.error?.message || error.message,
    );

    if (
      [
        'CI_MULTIPLE_KITCHEN_ON_NEW_ITEM',
        'BUSINESS_LOCATION_FOR_ITEM_NOT_WORKING_NOW',
        'ORDER_NOT_AVAILABLE',
        'CART_ITEMS_NOT_VISIBLE',
        'NO_STOCK',
      ].includes(error.message)
    ) {
      const productNotVisible = error.message === 'CART_ITEMS_NOT_VISIBLE';
      const isCartError = [ErrorCodes.OrderNotAvailable as string].includes(error.message);

      this._subscription.add(
        this._translateLabel(error.message, productNotVisible || isCartError ? 'CART' : '')
          .pipe(
            switchMap(res =>
              iif(
                () => productNotVisible,
                of(res).pipe(
                  tap(() => this._dialog.info({ message: res })),
                  delay(3000),
                  tap(() => window.location.reload()),
                ),
                of(res).pipe(tap(() => this._dialog.error({ message: res }))),
              ),
            ),
          )
          .subscribe(() => this._cdr.detectChanges()),
      );
    } else if (error.message === ErrorCodes.PosNoStock) {
      this._dialog.error({ message: 'PRODUCT_CATALOG.UNAVAILABLE_PRODUCT' });
      this._catalogReload.reloadCatalog();
    } else if (error.message === ErrorCodes.TemporarilyUnavailable) {
      this._dialog.error({ message: 'PRODUCT_CATALOG.TEMPORARILY_UNAVAILABLE_PRODUCT' });
      this._catalogReload.reloadCatalog();
    } else if (error.message === ErrorCodes.PosUnknownProducts) {
      this._dialog.error({ message: 'PRODUCT_CATALOG.UNKNOWN_PRODUCT' });
      this._catalogReload.reloadCatalog();
    } else if (isNoActiveOrderError) {
      this._dialog.info({ message: 'CART.ORDER_NOT_AVAILABLE' });
    } else if (error.status !== 401) {
      this._errorHandlerService.handleHttpError(error);
    }
  }

  private _getSessionSettings(): void {
    this.isTopBarVisibleProp = sessionStorage.getItem('topBarVisible');
  }
}
