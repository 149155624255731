import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, MonoTypeOperatorFunction, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { StorageService } from '@bend/storage';

import { ParamsService } from '../params';
import { actions } from './auth.actions';
import { AuthHttpService } from './auth.http.service';
import * as selectors from './auth.selectors';
import { LoginParams, LoginResponse, State } from './auth.type';

@Injectable()
export class AuthEffects implements OnInitEffects {
  // set initial store for auth
  setStorage$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.initEffects),
      switchMap(() => this._params.appSlugChanges),
      filter(appSlug => !!appSlug),
      first(),
      map(appSlug => {
        const token = this._storage.getToken(appSlug);
        const refreshToken = this._storage.getRefreshToken(appSlug);
        const isRegistered = this._storage.getIsRegistered(appSlug);
        const phone = this._storage.phone;

        if (token) return actions.initState({ token, refreshToken, isRegistered, phone });

        return actions.initStateError();
      }),
    ),
  );

  // login is the same as refresh token
  login$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.login),
      switchMap(({ url }) =>
        combineLatest([of(url), this._params.appSlug, this._params.queryParams(['place', '_waiter_mode', 'order_id'])]),
      ),
      mergeMap(([url, appSlug, [place, waiterMode, orderId]]) => {
        const loginRequest =
          waiterMode === 'true'
            ? this._auth.loginWaiter({ place, orderId: Number.parseInt(orderId), appSlug })
            : this._auth.login(this._createParams({ url, appSlug, place }));

        return loginRequest.pipe(
          this._putTokensInStorage(),
          map(loginResponse => actions.loginSuccess(loginResponse)),
          catchError(({ errorCode }) => of(actions.loginError({ errorCode }))),
        );
      }),
    ),
  );

  loginPos$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.loginPos),
      switchMap(() => this._params.queryParams(['place', 'reference'])),
      mergeMap(([place, reference]) =>
        this._auth.loginPos({ place, reference }).pipe(
          this._putTokensInStorage(),
          map(loginResponse => actions.loginPosSuccess(loginResponse)),
          catchError(({ errorCode }) => of(actions.loginPosError({ errorCode }))),
        ),
      ),
    ),
  );

  loginWifi$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.loginWifi),
      mergeMap(() =>
        this._auth.loginWifi().pipe(
          this._putTokensInStorage(),
          map(loginResponse => actions.loginWifiSuccess(loginResponse)),
          catchError(({ errorCode }) => of(actions.loginWifiError({ errorCode }))),
        ),
      ),
    ),
  );

  loginGps$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.loginGps),
      switchMap(({ gpsPoint }) => combineLatest([of(gpsPoint), this._params.queryParams(['place'])])),
      mergeMap(([gpsPoint, [place]]) =>
        this._auth.loginGps({ gpsPoint, place }).pipe(
          this._putTokensInStorage(),
          map(loginResponse => actions.loginSuccess(loginResponse)),
          catchError(({ errorCode }) => of(actions.loginError({ errorCode }))),
        ),
      ),
    ),
  );

  // start sign in process, send phone for receive confirm phone number code
  signIn$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.signIn),
      mergeMap(({ phone }) =>
        this._auth.signIn(phone).pipe(
          tap(() => (this._storage.phone = phone)),
          map(() => actions.signInSuccess({ phone })),
          catchError(({ errorCode }) => of(actions.signInError({ errorCode }))),
        ),
      ),
    ),
  );

  // resend confirm phone number code
  resendCode$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.resendCode),
      switchMap(() => this._store.pipe(select(selectors.phone)).pipe(first())),
      switchMap(phone =>
        this._auth.resendCode(phone).pipe(
          map(() => actions.resendCodeSuccess()),
          catchError(({ errorCode }) => of(actions.resendCodeError({ errorCode }))),
        ),
      ),
    ),
  );

  // confirm phone number by code from SMS message
  confirmCode$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.confirmCode),
      withLatestFrom(this._store.pipe(select(selectors.phone))),
      mergeMap(([{ code }, phone]) =>
        this._auth.confirmPhone({ phone, code }).pipe(
          this._putTokensInStorage(),
          map(loginResponse => actions.confirmCodeSuccess(loginResponse)),
          catchError(({ errorCode }) => of(actions.confirmCodeError({ errorCode }))),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.logOut),
      this._removeTokensInStorage(),
      mergeMap(() => [actions.logOutSuccess(), actions.login({ url: btoa(location.href) })]),
    ),
  );

  constructor(
    private _actions: Actions,
    private _auth: AuthHttpService,
    private _storage: StorageService,
    private _params: ParamsService,
    private _store: Store<State>,
  ) {}

  ngrxOnInitEffects(): Action {
    return actions.initEffects();
  }

  private _createParams({ url, appSlug, place }: Omit<LoginParams, 'refreshToken'>): LoginParams {
    const refreshToken = this._storage.getRefreshToken(appSlug);

    return {
      url,
      ...(refreshToken && { refreshToken }),
      ...(place && { place }),
    };
  }

  private _putTokensInStorage(): MonoTypeOperatorFunction<LoginResponse> {
    return switchMap(({ token, refreshToken, isRegistered = true, ...rest }) =>
      this._params.appSlug.pipe(
        tap(appSlug => this._storage.setToken(appSlug, token)),
        tap(appSlug => this._storage.setRefreshToken(appSlug, refreshToken)),
        tap(appSlug => this._storage.setIsRegistered(appSlug, isRegistered)),
        map(() => ({ token, refreshToken, isRegistered, ...rest })),
      ),
    );
  }

  private _removeTokensInStorage(): MonoTypeOperatorFunction<string> {
    return switchMap(() =>
      this._params.appSlug.pipe(
        tap(appSlug => this._storage.setToken(appSlug, null)),
        tap(appSlug => this._storage.setRefreshToken(appSlug, null)),
        tap(appSlug => this._storage.setIsRegistered(appSlug, null)),
      ),
    );
  }
}
