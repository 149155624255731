import { inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { ParamsService } from '@bend/store';

import { waiterModeQueryParam } from '../constants';

@Injectable({ providedIn: 'root' })
export class WaiterModeService {
  private readonly params = inject(ParamsService, { optional: true });

  isWaiterMode$: Observable<boolean> = this.params
    ? this.params.queryParams().pipe(
        map(params => params[waiterModeQueryParam]),
        map(waiterMode => !!waiterMode),
      )
    : of(false);
}
