import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvService } from '@bend/env';

import { RouterService } from '../abstracts';
import { Version } from '../types';

@Injectable({ providedIn: 'root' })
export class ApiShopService {
  constructor(
    private _params: RouterService,
    private _env: EnvService,
  ) {}

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/areas/areaId
   */
  area(version: Version, areaId: number): Observable<string> {
    return this.areas(version).pipe(this._patch(`/${areaId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/areas
   */
  areas(version: Version): Observable<string> {
    return this._api(version, '/areas');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs/catalogId/available-items
   */
  availableItems(version: Version, catalogId: number): Observable<string> {
    return this.catalog(version, catalogId).pipe(this._patch('/available-items'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs/catalogId
   */
  catalog(version: Version, catalogId: number): Observable<string> {
    return this.catalogs(version).pipe(this._patch(`/${catalogId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs
   */
  catalogs(version: Version): Observable<string> {
    return this._api(version, '/catalogs');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs-export
   */
  catalogsExport(version: Version): Observable<string> {
    return this._api(version, '/catalogs-export');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalog-item-categories
   */
  catalogCategories(version: Version): Observable<string> {
    return this._api(version, '/catalog-item-categories');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs/import/pos/importId
   */
  catalogsImportsPosId(version: Version, importId: string): Observable<string> {
    return this.catalogsImportsPos(version).pipe(this._patch(`/${importId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs/import/csv/importId
   */
  importProductsFromCsvId(version: Version, importId: string): Observable<string> {
    return this.importProductsFromCsv(version).pipe(this._patch(`/${importId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/duplicate-catalog
   */
  duplicateCatalog(version: Version): Observable<string> {
    return this._api(version, '/duplicate-catalog');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/items/itemId
   */
  item(version: Version, itemId: string): Observable<string> {
    return this.items(version).pipe(this._patch(`/${itemId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/items/reorder
   */
  itemsReorder(version: Version): Observable<string> {
    return this.items(version).pipe(this._patch('/reorder'));
  }

  /**
   * @returns https://shop-api.smilein.fr/api/version/userType/appSlug/items/reorder-children
   */
  itemsReorderChildren(version: Version): Observable<string> {
    return this.items(version).pipe(this._patch('/reorder-children'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/items
   */
  items(version: Version): Observable<string> {
    return this._api(version, '/items');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/items
   */
  itemsForMenus(version: Version): Observable<string> {
    return this._api(version, '/items-for-menus');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/option-group-subitems
   */
  optionGroupSubitems(version: Version, optionGroupSubitemId: number): Observable<string> {
    return this._api(version, `/option-group-subitems/${optionGroupSubitemId}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/option-groups
   */
  optionGroups(version: Version): Observable<string> {
    return this._api(version, '/option-groups');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/option-groups/optionGroupId
   */
  optionGroup(version: Version, optionGroupId: number): Observable<string> {
    return this._api(version, `/option-groups/${optionGroupId}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/option-groups-pos
   */
  optionGroupsPos(version: Version, posId: string): Observable<string> {
    return this._api(version, `/option-groups-pos/${posId}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/production-readiness
   */
  productionReadiness(version: Version): Observable<string> {
    return this._api(version, '/production-readiness');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/tags
   */
  tags(version: Version): Observable<string> {
    return this._api(version, '/tags');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/tags/id
   */
  tag(version: Version, id: number): Observable<string> {
    return this._api(version, `/tags/${id}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/transactions
   */
  transactions(version: Version): Observable<string> {
    return this._api(version, '/transactions');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/transactions
   */
  transaction(version: Version, id: number): Observable<string> {
    return this._api(version, `/transactions/${id}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/tvas/tvaId
   */
  tva(version: Version, tvaId: string): Observable<string> {
    return this.tvas(version).pipe(this._patch(`/${tvaId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/tvas
   */
  tvas(version: Version): Observable<string> {
    return this._api(version, '/tvas');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/working-hours
   */
  workingHours(version: Version): Observable<string> {
    return this._api(version, '/working-hours');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/pos-sync-app
   */
  posSyncApp(version: Version): Observable<string> {
    return this._api(version, '/pos-sync-app');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/pos-items-with-option-groups/productId
   */
  posItemsWithOptionGroups(version: Version, productId: string): Observable<string> {
    return this._api(version, `/pos-items-with-option-groups/${productId}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/items-search
   */
  itemsSearch(version: Version): Observable<string> {
    return this._api(version, '/items-search');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/options-search
   */
  optionsSearch(version: Version): Observable<string> {
    return this._api(version, '/options-search');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/option-groups-search
   */
  optionGroupsSearch(version: Version): Observable<string> {
    return this._api(version, '/option-groups-search');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/call-waiters
   */
  callWaiters(version: Version): Observable<string> {
    return this._api(version, '/call-waiters');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/menu/menuId
   */
  menu(version: Version, menuId: number): Observable<string> {
    return this.menus(version).pipe(this._patch(`/${menuId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/menu
   */
  menus(version: Version): Observable<string> {
    return this._api(version, '/menu');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/menu/reorder
   * */
  menuReorder(version: Version): Observable<string> {
    return this._api(version, '/menu/reorder');
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/menus/:menuId
   */
  catalogMenuCategories(version: Version, menuId: number): Observable<string> {
    return this.catalogMenus(version).pipe(this._patch(`/${menuId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/menus
   */
  catalogMenus(version: Version): Observable<string> {
    return this._api(version, '/menus');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/order-items/command
   */
  orderItemsCommand(version: Version): Observable<string> {
    return this.orderItems(version).pipe(this._patch('/command'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/order-items/item
   */
  orderItem(version: Version, item: number): Observable<string> {
    return this.orderItems(version).pipe(this._patch(`/${item}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/menu-items/item
   */
  menuItem(version: Version, item: string): Observable<string> {
    return this.menuItems(version).pipe(this._patch(`/${item}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/order-items
   */
  orderItems(version: Version): Observable<string> {
    return this._api(version, '/order-items');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/menu-items
   */
  menuItems(version: Version): Observable<string> {
    return this._api(version, '/menu-items');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/tips
   */
  tips(version: Version): Observable<string> {
    return this._api(version, '/tips');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/simplified/orderId
   */
  orderSimplified(version: Version, orderId: number): Observable<string> {
    return this.ordersSimplified(version).pipe(this._patch(`/${orderId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/working-hours
   */
  orderWorkingHours(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/working-hours'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/capacity
   */
  orderCapacity(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/capacity'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/orders/ready-sms/:orderId
   */
  readySms(version: Version, orderId: number): Observable<string> {
    return this.orders(version).pipe(this._patch(`/ready-sms/${orderId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/reopen
   */
  orderReopenUserSession(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/reopen'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/last-updated/orderId
   */
  orderLastUpdated(version: Version, orderId: number): Observable<string> {
    return this.orders(version).pipe(this._patch(`/last-updated/${orderId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/orderId/check
   */
  orderCheck(version: Version, orderId: number): Observable<string> {
    return this.order(version, orderId).pipe(this._patch('/check'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/orderId
   */
  order(version: Version, orderId: number): Observable<string> {
    return this.orders(version).pipe(this._patch(`/${orderId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/orderId/waiter-login
   */
  loginWaiter(version: Version, orderId: number): Observable<string> {
    return this.orders(version).pipe(this._patch(`/${orderId}/waiter-login`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/close/orderId
   */
  orderClose(version: Version, orderId: number): Observable<string> {
    return this.orders(version).pipe(this._patch(`/close/${orderId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/receipt/orderId
   */
  orderReceipt(version: Version, orderId: number): Observable<string> {
    return this.orders(version).pipe(this._patch(`/receipt/${orderId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/simplified
   */
  ordersSimplified(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/simplified'));
  }

  exportOrders(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/export'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/details
   */
  details(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/details'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/orders/user-details
   */
  userDetails(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/user-details'));
  }

  /**
   * @returns https://shop-api.smilein.com/api/version/userType/appSlug/orders/send-to-kitchen
   */
  sendToKitchen(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/send-to-kitchen'));
  }

  /**
   * @returns https://shop-api.smilein.com/api/version/userType/appSlug/orders/address
   */
  address(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/address'));
  }

  /**
   * @returns https://shop-api.smilein.com/api/version/userType/appSlug/orders/history
   */
  history(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/history'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/orders/check-today
   */
  checkHasOrderToday(version: Version): Observable<string> {
    return this.orders(version).pipe(this._patch('/check-today'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/user/:appSlug/orders
   */
  orders(version: Version): Observable<string> {
    return this._api(version, '/orders');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/pay-ordered/orderId
   */
  payOrdered(version: Version, orderId: number): Observable<string> {
    return this._api(version, `/pay-ordered/${orderId}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/item-categories/idCategory
   */
  itemCategory(version: Version, idCategory: number): Observable<string> {
    return this.itemCategories(version).pipe(this._patch(`/${idCategory}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/item-categories/extended
   */
  itemCategoriesExtended(version: Version): Observable<string> {
    return this.itemCategories(version).pipe(this._patch('/extended'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/item-categories/reorder
   */
  itemCategoriesReorder(version: Version): Observable<string> {
    return this.itemCategories(version).pipe(this._patch('/reorder'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/item-categories
   */
  itemCategories(version: Version): Observable<string> {
    return this._api(version, '/item-categories');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/parent-item-categories
   */
  parentItemCategories(version: Version): Observable<string> {
    return this._api(version, '/parent-item-categories');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs/import/pos
   */
  catalogsImportsPos(version: Version): Observable<string> {
    return this._api(version, '/catalogs/import/pos');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/catalogs/import/csv
   */
  importProductsFromCsv(version: Version): Observable<string> {
    return this._api(version, '/catalogs/import/csv');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/applications-payment-config
   */
  applicationsPaymentConfig(version: Version): Observable<string> {
    return this._api(version, '/applications-payment-config');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/promo-code
   */
  promoCode(version: Version): Observable<string> {
    return this._api(version, '/promo-code');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/payment-methods/paymentMethodId
   */
  paymentMethod(version: Version, paymentMethodId: string): Observable<string> {
    return this.paymentMethods(version).pipe(this._patch(`/${paymentMethodId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/payment-methods
   */
  paymentMethods(version: Version): Observable<string> {
    return this._api(version, '/payment-methods');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/payment-cash
   */
  paymentCash(version: Version): Observable<string> {
    return this._api(version, '/payment-cash');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/places-light
   */
  placesLight(version: Version): Observable<string> {
    return this._api(version, '/places-light');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/places
   */
  places(version: Version): Observable<string> {
    return this._api(version, '/places');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/places/placeId
   */
  place(version: Version, placeId: number): Observable<string> {
    return this.places(version).pipe(this._patch(`/${placeId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/business-locations
   */
  businessLocations(version: Version): Observable<string> {
    return this._api(version, '/business-locations');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/business-locations/businessLocationId
   */
  businessLocation(version: Version, businessLocationId: number): Observable<string> {
    return this.businessLocations(version).pipe(this._patch(`/${businessLocationId}`));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/payment/stripe-response
   */
  stripeResponse(version: Version): Observable<string> {
    return this.payment(version).pipe(this._patch('/stripe-response'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/payment/paygreen-response
   */
  paygreenResponse(version: Version): Observable<string> {
    return this.payment(version).pipe(this._patch('/paygreen-response'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/payment/tpa-response
   */
  tpaResponse(version: Version): Observable<string> {
    return this.payment(version).pipe(this._patch('/tpa-response'));
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/payment
   */
  payment(version: Version): Observable<string> {
    return this._api(version, '/payment');
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/agent-view-payment
   */
  agentViewPayment(version: Version): Observable<string> {
    return this._api(version, '/agent-view-payment');
  }

  /**
   * @returns https://api.smilein.io/shop/api/version/userType/appSlug/addresses
   */
  addresses(version: Version): Observable<string> {
    return this._api(version, '/addresses');
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/places-by-slug/:placeSlug
   */
  placeBySlug(version: Version, placeSlug: string): Observable<string> {
    return this._api(version, `/places-by-slug/${placeSlug}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/generate-link
   */
  generateLink(version: Version): Observable<string> {
    return this._api(version, '/loyalty/generate-link');
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/sponsor-eligibility
   */
  sponsorEligibility(version: Version): Observable<string> {
    return this._api(version, '/loyalty/sponsor-eligibility');
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/invited-eligibility/:code
   */
  invitedEligibility(version: Version, code: string): Observable<string> {
    return this._api(version, `/loyalty/invited-eligibility/${code}`);
  }

  /**
   * @return https://shop-smilein.cosmilein.io/shop/api/:version/:userType/:appSlug/item-recommendations/:itemId
   */
  itemRecommendations(version: Version, itemId: number): Observable<string> {
    return this._api(version, `/item-recommendations/${itemId}`);
  }

  /**
   * @returns https://shop-smilein.cosmilein.io/shop/api/:version/:userType/:appSlug/:patch
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/recommendation-groups
   */
  recommendationGroups(version: Version): Observable<string> {
    return this._api(version, '/recommendation-groups');
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/card-recommendations
   * */
  recommendationGroupsByPlace(version: Version): Observable<string> {
    return this._api(version, '/card-recommendations');
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/recommendation-groups/:id
   */
  recommendationGroup(version: Version, id: number): Observable<string> {
    return this._api(version, `/recommendation-groups/${id}`);
  }

  /**
   * @returns https://api.smilein.io/shop/api/:version/:userType/:appSlug/catalogs-populated
   */
  catalogsPopulated(version: Version): Observable<string> {
    return this._api(version, '/catalogs-populated');
  }

  /**
   * @returns https://shop-smilein.cosmilein.io/shop/api/version/userType/appSlug/patch
   */
  private _api(version: Version, patch: string): Observable<string> {
    return this._params.appSlug.pipe(
      map(appSlug => `${this._env.apiHost}/shop/api/${version}/${this._env.userType}/${appSlug}${patch}`),
    );
  }

  private _patch(patch: string): OperatorFunction<string, string> {
    return map(api => `${api}${patch}`);
  }
}
